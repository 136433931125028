import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-scroll";

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isScroll: false,
      isScroll2: false,
      isMobile: false,
      page: '',
      showBtn: false
    };
  }

  componentDidMount() {
    console.log(window.location.pathname)
    if (window.location.pathname === '/contact') {
      this.setState({
        page: 'contact'
      })
    }
    document.addEventListener("scroll", () => {
      let isScroll = window.scrollY;
      if (isScroll > 15) {
        this.setState({
          isScroll2: true
        });

      } else {
        this.setState({
          isScroll2: false
        });
      }
      if (isScroll > 980) {
        this.setState({
          isScroll: true
        });

      } else {
        this.setState({
          isScroll: false
        });
      }
    });
  }
  componentWillMount() {
    if (window.location.pathname === '/contact') {
      this.setState({
        page: 'contact'
      })
    }
    let windowWidth = window.innerWidth;
    if (windowWidth <= 768) {
      this.setState({
        isMobile: true
      })
    }
    else {
      this.setState({
        isMobile: false
      })
    }
  }
  render() {
    let className = "header-menu";
    if (this.state.isScroll) {
      className += ' header-menu-scroll';
    }
    // if (this.state.isMobile) {
    //   className += ' mobileBgHeader'
    // }
    return (
      <div
        className={this.state.isScroll2 && this.state.page != 'contact' ? "header headerTop" : "header "}
      >
        <Navbar bg="" expand="lg" className={this.state.isScroll && this.state.page != 'contact' ? "" : "navbarwithoutBtn"} >
          <Navbar.Brand href="/">
            <img
              src="/images/bountye.png"
              alt="bountye"
              style={{ width: 122, height: 36 }}
            />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav" className="basic-navbar-nav">
            <Nav className="mr-auto header-menu-item " >
              {this.state.page === 'contact' ?
                <div className="header-menu-item-center">
                  <a className='itemInHeader' href="/#aboutUs">About us</a>
                  <a className='itemInHeader' href="/#problem">The problem</a>
                  <a className='itemInHeader' href="/#howWork">How it works</a>
                </div>
                :
                <div className="header-menu-item-center">
                  <Link
                    activeClass="active"
                    to="aboutUs"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1500}
                    onSetActive={this.handleSetActive}
                  >
                    About us
                  </Link>
                  <Link
                    activeClass="active"
                    to="problem"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={2000}
                    onSetActive={this.handleSetActive}
                  >
                    The problem
                </Link>
                  <Link
                    activeClass="active"
                    to="howWork"
                    spy={true}
                    smooth={true}
                    offset={-130}
                    duration={2000}
                    onSetActive={this.handleSetActive}
                  >
                    How it works
                </Link>
                </div>
              }
            </Nav>
          </Navbar.Collapse >
          {this.state.isScroll && this.state.page != 'contact' && <a href='/contact' className='button-header'>Contact us</a>}
        </Navbar >
      </div >
    );
  }
}

export default Header;
