import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

export default class HowWork extends Component {
  render() {
    return (
      <Row className="howWork" id="howWork">
        <span className="howWork-text-top">how it works</span>
        <span className="howWork-title">
          Bountye’s proprietary algorithms do more than extract data
        </span>
        <Row className="howWork-item-group">
          <Col xs="12" md="4" className="howWork-item">
            <img
              src="/images/structured.png"
              alt="structured"
              className="howWork-item-image"
            />
            <span className="howWork-item-title">Structured Data</span>
            <span className="howWork-item-description">
              Maps relational data schema automatically
            </span>
          </Col>
          <Col xs="12" md="4" className="howWork-item">
            <img
              src="/images/relevance.png"
              alt="Relevance"
              className="howWork-item-image"
            />
            <span className="howWork-item-title">Relevance</span>
            <span className="howWork-item-description">
              Aggregated data amalgamated and searchable with accuracy
            </span>
          </Col>
          <Col xs="12" md="4" className="howWork-item">
            <img
              src="/images/personalisation.png"
              alt="Personalisation"
              className="howWork-item-image"
            />
            <span className="howWork-item-title">Personalisation</span>
            <span className="howWork-item-description">
              Using machine learning, algorithms, analytics, and filters
            </span>
          </Col>
        </Row>
        <div className="howWork-item-group-bottom">
          {/* <button as={NavLink} to="/contact" className="howWork-item-group-bottom-button">
          Get started
          </button> */}
          <a href="/contact" className="first-view-contactus-btn">Contact us</a>
        </div>
      </Row>
    );
  }
}
