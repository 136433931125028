import React, { Component } from "react";

export default class Wizard2 extends Component {
  render() {
    return (
      <div className="wizard2">
        <div className="wizard2-column-left col-md-6 col-xs-12">
          <img
            src="/images/wizard2.png"
            alt="step 2"
            className="wizard2-column-left-image"
          />
        </div>
        <div className="wizard2-column-right col-md-6 col-xs-12">
          <label className="wizard2-column-right-step">Step 2</label>
          <h3 className="wizard2-column-right-title">Using Machine Learning</h3>
          <p className="wizard2-column-right-description">
          Data is auto-populated into a single taxonomy, allowing it to be accessed via categories and searched. 
          </p>

          <p className="wizard2-column-right-item-text-group">
            <i className="mdi mdi-reload" />
            <span className="wizard2-column-right-item-text">
              Once data has been extracted it is automatically loaded on the
              main website or test site.
            </span>
          </p>
          <p className="wizard2-column-right-item-text-group">
            <i className="mdi mdi-bell-outline" />
            <span className="wizard2-column-right-item-text">
              Confirmation is provided notifying the user
              <br />
              of total extracted items.
            </span>
          </p>
        </div>
      </div>
    );
  }
}
