import React, { Component } from "react";
import { Carousel, Col } from "react-bootstrap";

export default class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      direction: null
    };
  }
  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction
    });
  };
  render() {
    const { index, direction } = this.state;
    return (
      <div>
        <Carousel
          activeIndex={index}
          direction={direction}
          onSelect={this.handleSelect}
          className="slider"
          nextIcon={
            <i
              className="mdi mdi-chevron-right"
              style={{ color: "#232837", fontSize: "30px" }}
            />
          }
          prevIcon={
            <i
              className="mdi mdi-chevron-left"
              style={{ color: "#232837", fontSize: "30px" }}
            />
          }
        >
          <Carousel.Item>
            <Col className="carousel-inner-item-group">
              <img
                src="/images/chat.png"
                alt="bountye"
                className="carousel-inner-image"
              />
              <p style={{ color: "black" }} className="carousel-inner-title">
                15 million minutes worth of quick videos are recorded and
                watched on Bountye every month. Here's why.
              </p>
              <p className="carousel-inner-description">
                Ryan Richard, Co-founder
              </p>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <Col className="carousel-inner-item-group">
              <img
                src="/images/chat.png"
                alt="bountye"
                className="carousel-inner-image"
              />
              <p style={{ color: "black" }} className="carousel-inner-title">
                15 million minutes worth of quick videos are recorded and
                watched on Bountye every month. Here's why.
              </p>
              <p className="carousel-inner-description">
                Ryan Richard, Co-founder1
              </p>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <Col className="carousel-inner-item-group">
              <img
                src="/images/chat.png"
                alt="bountye"
                className="carousel-inner-image"
              />
              <p style={{ color: "black" }} className="carousel-inner-title">
                15 million minutes worth of quick videos are recorded and
              </p>
              <p className="carousel-inner-description">
                Ryan Richard, Co-founder2
              </p>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <Col className="carousel-inner-item-group">
              <img
                src="/images/chat.png"
                alt="bountye"
                className="carousel-inner-image"
              />
              <p style={{ color: "black" }} className="carousel-inner-title">
                15 million minutes worth of quick videos are recorded and
                watched on Bountye every month. Here's why.
              </p>
              <p className="carousel-inner-description">
                Ryan Richard, Co-founder3
              </p>
            </Col>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}
