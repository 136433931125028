import React, { Component } from "react";

export default class DemoSection extends Component {
  render() {
    return (

      <div className="demoSection" id="downloadApp">
        <div className="first-view-Untitled_Artwork831"></div>
        <div className="first-view-Untitled_Artwork841"></div>
        <div className="first-view-Untitled_Artwork861"></div>
        <div className="first-view-Untitled_Artwork851"></div>
        <h1 className="demoSection-title">Build what’s next</h1>
        <a href="/contact" className="first-view-contactus-btn">Contact us</a>
        <p className="demoSection-description">
          Our consultants are ready to help!
        </p>
      </div>
    );
  }
}
