import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import ContactInfoItem from "./ContactInfoItem";



export default class ContactInfo extends Component {
    render() {
        const { src, title, description, icon } = this.props;
        return (
            <div className="contactInfo-container">
                <div className="row" >
                    <div className="col" id='problem'>
                        <ContactInfoItem className="float-right"
                            src='/images/union.png'
                            title="Suite 144, 1 Mitchell Road Alexandria, NSW 2015 AUSTRALIA"
                            description="Bountye PTY LTD (ACN 601720361)" />
                        {/* <div className="vl"></div> */}
                    </div>
                    <div className="col">
                        <ContactInfoItem
                            title="contact@bountye.com"
                            description=""
                            src='/images/union1.png' />
                    </div>
                </div>
            </div>
        );
    }
}
