import React, { Component } from "react";
import ContactInfo from "./contactInfo";
import ContactForm from "./contactForm";

class Contact extends Component {
    render() {
        return (
            <div className='contatctUsMain'>
                <div className='contactUs-grid'>
                    <h2 className="page-title-text" >Contact us</h2>
                    <div className='description'>
                        <p className="page-description">Got a question? We’d love to hear from you.<br /> Send us a message and we’ll respond as soon as possible.</p>
                        <p className="page-description">or send us an email to <span className='description-email'>contact@bountye.com</span></p>
                    </div>
                    <ContactForm />
                </div>
            </div>
        );
    }
}

export default Contact;