import React, { Component } from "react";

export default class Insights extends Component {
  render() {
    return (
      <div className="insights">
        <div className="insights-column-left col-md-6 col-xs-12">
          <label className="insights-column-left-step">INSIGHTS OUTPUT</label>
          <h3 className="insights-column-left-title">Data Analysis</h3>
          <p className="insights-column-left-description">
            Bountye’s platform includes a dashboard with detailed insights of
            aggregated data.
          </p>
          <div className="insights-column-left-item">
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Click throughs
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Response times
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Popular searches
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                No result searches
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Traffic volumes
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Cache freshness
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Data filtering
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Synchronising schedule
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Item velocity
              </span>
            </p>
            <p className="insights-column-left-item-text-group">
              <i className="mdi mdi-check background-icon
              " />
              <span className="insights-column-left-item-text">
                Sales volume
              </span>
            </p>
          </div>
        </div>
        <div className="insights-column-right col-md-6 col-xs-12">
          <div className="insights-column-right-image-group">
            <img
              src="/images/insights.png"
              alt="insights1"
              className="insights-column-right-image1"
            />
          </div>
        </div>
      </div>
    );
  }
}
