import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Card, Row } from "react-bootstrap";


class Index extends Component {
  render() {
    return (
      <Row className="first-view" id="aboutUs">
        <Row className='box-title'>
          <h2 className="first-view-text">
            Scrape, Compile, and Search Data
            all With One Solution
          </h2>
          <p className="text-muted first-view-description">We make extracted data useful by indexing and parsing results against relevancy scores based on your business requirements</p>
          <a href="/contact" className="first-view-contactus-btn">Contact us</a>
        </Row>
        {/* <p className="first-view-ordertext">Try our AI powered data aggregation platform!</p> */}

        <Card className="first-view-MaximeCard">
          <Card.Img
            variant="top"
            src={"/images/1.png"}
            className="yourStore-box-header-card-image"
          />
          <Card.Body style={{ padding: 0, margin: 0 }}>
            <div className="yourStore-box-header-card-description">
              <div>
                <Card.Text className="yourStore-box-header-card-title">
                  Maxime Dress
                </Card.Text>
                <Card.Text className="yourStore-box-header-card-text">
                  ebay
                </Card.Text>
              </div>
              <div style={{ margin: 'auto 12px auto 0px' }}>
                <img src={"/images/ebay.png"} style={{ height: 32 }} />
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="first-view-Golden-Brac-smallCard">
          <Card.Img
            variant="top"
            src={"/images/5.png"}
            className="yourStore-box-header-card-image"
          />
          <Card.Body style={{ padding: 0, margin: 0 }}>
            <div className="yourStore-box-header-card-description">
              <div>
                <Card.Text className="yourStore-box-header-card-title">
                  Google Home
                </Card.Text>
                <Card.Text className="yourStore-box-header-card-text">
                  Amazon
                </Card.Text>
              </div>
              <div style={{ margin: 'auto 12px auto 0px' }}>
                <img src={"/images/amazon.png"} style={{ height: 20 }} />
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className="first-view-iphonesCard">
          <Card.Img
            variant="top"
            src={"/images/6.png"}
            className="yourStore-box-header-card-image"
          />
          <Card.Body style={{ padding: 0, margin: 0 }}>
            <div className="yourStore-box-header-card-description">
              <div>
                <Card.Text className="yourStore-box-header-card-title">
                  iPhone
                </Card.Text>
                <Card.Text className="yourStore-box-header-card-text">
                  Amazon
                </Card.Text>
              </div>
              <div style={{ margin: 'auto 12px auto 0px' }}>
                <img src={"/images/amazon.png"} style={{ height: 20 }} />
              </div>
            </div>
          </Card.Body>
        </Card>


        <Card className="first-view-Golden-BraceCard">
          <Card.Img
            variant="top"
            src={"/images/3.png"}
            className="yourStore-box-header-card-image"
          />
          <Card.Body style={{ padding: 0, margin: 0 }}>
            <div className="yourStore-box-header-card-description">
              <div>
                <Card.Text className="yourStore-box-header-card-title">
                  V-Neck Tee
                </Card.Text>
                <Card.Text className="yourStore-box-header-card-text">
                  The Iconic
                </Card.Text>
              </div>
              <div style={{ margin: 'auto 12px auto 0px' }}>
                <img src={"/images/iconic.png"} style={{ height: 40 }} />
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className="first-view-MacbookProCard">
          <Card.Img
            variant="top"
            src={"/images/7.png"}
            className="yourStore-box-header-card-image"
          />
          <Card.Body style={{ padding: 0, margin: 0 }}>
            <div className="yourStore-box-header-card-description">
              <div>
                <Card.Text className="yourStore-box-header-card-title">
                  Macbook Pro
                </Card.Text>
                <Card.Text className="yourStore-box-header-card-text">
                  Amazon
                </Card.Text>
              </div>
              <div style={{ margin: 'auto 12px auto 0px' }}>
                <img src={"/images/amazon.png"} style={{ height: 20 }} />
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="first-view-board">
          <img src={"/images/Untitled_Artwork 22 1.png"} style={{ maxHeight: 480 }} />
        </div>
        <div className="first-view-Untitled_Artwork83"></div>
        <div className="first-view-Untitled_Artwork84"></div>
        <div className="first-view-Untitled_Artwork86"></div>
        <div className="first-view-Untitled_Artwork85"></div>
        <div className="firstview-vector"></div>
      </Row>
    );
  }
}

export default Index;
