import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, Redirect
} from "react-router-dom";
import './assets/styles/index.scss'

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/home'
import Index from './components/home';
import Footer from './components/footer';
import Header from './components/header';
import Contact from './components/contact_us/contact_us';
import Terms from './components/terms';
import PrivacyPolicy from './components/privacyPolicy';
import Privacy from './components/privacy';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route path="/contact"><Contact /></Route>
            <Route path="/privacy"> <Privacy /> </Route>
            <Route path="/privacy-policy"> <PrivacyPolicy /> </Route>
            <Route path="/terms"> <Terms /> </Route>
            <Redirect to="/" />
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App