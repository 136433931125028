import React, { Component } from "react";

export default class Wizard1 extends Component {
  render() {
    return (
      <div className="wizard1">
        <div className="wizard1-column-left col-md-6 col-xs-12">
          <label className="wizard1-column-left-step">Step 1</label>
          <h3 className="wizard1-column-left-title">Only a URL is Required</h3>
          <p className="wizard1-column-left-description1">
          Extracting data is simple, easy, and fast. We only require a URL or endpoint.
          </p>
          <div className="wizard1-column-left-item">
            <p className="wizard1-column-left-item-text">
              <i className="mdi mdi-check" />
              Direct URLs
            </p>
            <p className="wizard1-column-left-item-text">
              <i className="mdi mdi-check" />
              Endpoint URLs
            </p>
          </div>
          <p className="wizard1-column-left-description2">
          We also provide external API’s so that you can add your JSON or XML file.
          </p>
        </div>
        <div className="wizard1-column-right col-md-6 col-xs-12">
          <img
            src="/images/wizard1.png"
            alt="step 1"
            className="wizard1-column-right-image"
          />
        </div>
      </div>
    );
  }
}
