import React, { Component } from "react";
import { Card } from "react-bootstrap";

export default class CardBox extends Component {
  render() {
    const { src, title, description, icon } = this.props;
    return (
      <Card className="yourStore-box-header-card">
        <Card.Img
          variant="top"
          src={src}
          className="yourStore-box-header-card-image"
        />
        <Card.Body style={{ padding: 0, margin: 0 }}>
          <div className="yourStore-box-header-card-description">
            <div>
              <Card.Text className="yourStore-box-header-card-title">
                {title}
              </Card.Text>
              <Card.Text className="yourStore-box-header-card-text">
                {description}
              </Card.Text>
            </div>

          </div>
        </Card.Body>
      </Card>
    );
  }
}
