import React, { Component } from 'react'

export default class Privacy extends Component {


    render() {
        return (
            <div className='container-with-out-text'>
                <h1>Privacy</h1>
            </div>
        )
    }
}
