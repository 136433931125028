import React, { Component } from "react";
import Index from "./index";
import YourStore from "./yourStore";
import Solution from "./solution";
import HowWork from "./howWork";
import Wizard1 from "./wizard1";
import Wizard2 from "./wizard2";
import Insights from "./insights";
import Slider from "./slider";
import DemoSection from "./demoSection";
import Footer from "./../footer";

class Home extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <Index/>
        </div>
        <YourStore />
        <div className="container">
          <Solution />
          <HowWork />
          <Wizard1 />
          <Wizard2 />
          <Insights />
        </div>
        <DemoSection />
      </div>
    );
  }
}
export default Home;
