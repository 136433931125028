import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {


    render() {
        return (
            <div className='container-with-out-text'>
                <h1>Privacy policy</h1>
            </div>
        )
    }
}
