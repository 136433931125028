import axios from "axios";
import React, { Component } from "react";


export default class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            email: '',
            business: '',
            object: '',
            message: '',
            errName: '',
            errPhone: '',
            errEmail: '',
            errBusiness: '',
            errObject: '',
            errMessage: '',
            errMessageApi: '',
        }
    }

    handleSendMessage = async () => {
        let { name, phone, email, object, errPhone, errObject, errEmail, errName } = this.state;
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        let url = "https://hooks.slack.com/services/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"
        let data = { "text": "You have a new message from Bountye SaaS landing page.\n*Name:* " + this.state.name + "\n*Phone:* " + this.state.phone + "\n*Email:* " + this.state.email + "\n*Business name:* " + this.state.business + "\n*Message:* " + this.state.message + "" };
        if (errName === '' && errEmail === '' && errPhone === '' && name != '' && email != '' && phone != '') {
            debugger
            const response = await axios.post(url, JSON.stringify(data), {
                withCredentials: false,
                transformRequest: [(data, headers) => {
                    delete headers.post["Content-Type"]
                    return data
                }]
            })
            if (response.data === 'ok') {
                this.setState({
                    errMessageApi: 'Thank you for messageing',
                    name: '',
                    phone: '',
                    email: '',
                    business: '',
                    object: '',
                    message: '',

                })
            }
            console.log('response', response)
        }



    }
    HandleChangeInput = (value, filed) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        switch (filed) {
            case 'name':
                this.setState({
                    name: value,
                    errName: ''
                }, () => {
                    if (!this.state.name) {
                        this.setState({
                            errName: 'Name is required.'
                        })
                    } else {
                        this.setState({
                            errName: ''
                        })
                    }
                })
                break;
            case 'phone':
                this.setState({
                    phone: value,
                    errPhone: ''
                }, () => {
                    if (!this.state.phone) {
                        this.setState({
                            errPhone: 'Phone is required.'
                        })
                    } else if (!this.state.phone.match(phoneno)) {
                        this.setState({
                            errPhone: 'Phone number is not valid.'
                        })
                    } else {
                        this.setState({
                            errPhone: ''
                        })
                    }
                })
                break;
            case 'email':
                this.setState({
                    email: value,
                    errEmail: ''
                }, () => {
                    if (!this.state.email) {
                        this.setState({
                            errEmail: 'Email is required.'
                        })
                    } else if (!re.test(String(this.state.email).toLowerCase())) {
                        this.setState({
                            errEmail: 'Email is not valid.'
                        })
                    } else {
                        this.setState({
                            errEmail: ''
                        })
                    }
                })
                break
            case 'business':
                this.setState({
                    business: value,
                    errBusiness: ''
                })
                break
            case 'message':
                this.setState({
                    message: value,
                    errMessage: ''
                })
                break
            case 'object':
                this.setState({
                    object: value,
                    errObject: ''
                })
                break
            default:
                break;
        }
    }

    render() {
        return (
            <div className="contactForm-box">
                <h4 className='form-title'>What can we help you with?</h4>
                <div className='main-form'>
                    <label className="contactForm-form-group" id='howWork'>
                        <p className="contactForm-lable marginToInput">Name</p>
                        <input value={this.state.name} onChange={(e) => this.HandleChangeInput(e.target.value, 'name')} className="contactForm-InputText contactForm-inputFont" type="text" name="name" />
                        {this.state.errName != "" && <span style={{ color: 'red', fontSize: 12 }}>{this.state.errName}</span>}
                    </label>
                    <label className="contactForm-form-group" >
                        <p className="contactForm-lable marginToInput">Phone</p>
                        <input value={this.state.phone} onChange={(e) => this.HandleChangeInput(e.target.value, 'phone')} className="contactForm-InputText contactForm-inputFont" type="text" name="phone" />
                        {this.state.errPhone != "" && <span style={{ color: 'red', fontSize: 12 }}>{this.state.errPhone}</span>}
                    </label>
                    <label className="contactForm-form-group">
                        <p className="contactForm-lable marginToInput">Email address</p>
                        <input value={this.state.email} onChange={(e) => this.HandleChangeInput(e.target.value, 'email')} className="contactForm-InputText contactForm-inputFont" type="email" name="email" />
                        {this.state.errEmail != "" && <span style={{ color: 'red', fontSize: 12 }}>{this.state.errEmail}</span>}
                    </label>
                    <label className="contactForm-form-group">
                        <p className="contactForm-lable marginToInput">Business name</p>
                        <input value={this.state.business} onChange={(e) => this.HandleChangeInput(e.target.value, 'business')} className="contactForm-InputText contactForm-inputFont" type="text" name="business" />
                        {this.state.errBusiness != "" && <span style={{ color: 'red', fontSize: 12 }}>{this.state.errBusiness}</span>}
                    </label>
                    {/* <label className="contactForm-form-group">
                        <p className="contactForm-lable marginToInput">Object of the message</p>
                        <select className="contactForm-InputText" name="objectOfMessage" onChange={e => this.HandleChangeInput(e.target.value, "object")}>
                            <option value=''>Select Object</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                        </select>
                        {this.state.errObject != "" && <span style={{ color: 'red', fontSize: 12 }}>{this.state.errObject}</span>}
                    </label> */}
                    <label className="contactForm-form-group" style={{ height: "120px" }}>
                        <p className="contactForm-lable marginToInput">Message</p>
                        <textarea value={this.state.message} onChange={(e) => this.HandleChangeInput(e.target.value, 'message')} className="contactForm-InputTextErea contactForm-inputFont" name="message" rows="5"></textarea>
                    </label>
                </div>
                <div className="button-box">
                    <input type="button" onClick={this.handleSendMessage} className="contactForm-sendBtn" value="Send"></input>
                </div>
                {this.state.errMessageApi != "" && <span style={{ color: 'red', fontSize: 12 }}>{this.state.errMessageApi}</span>}
            </div>
        );
    }
}
