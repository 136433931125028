import React, { Component } from "react";

export default class Solution extends Component {
  render() {
    return (
      <div className="solution" id="problem">
        <h1 className="solution-title">There is no SaaS solution that</h1>
        <div className="solution-item-group">
          <div className="solution-item">
            <img
              src="/images/icon1.png"
              alt="icon1"
              className="solution-item-image"
            />
            <p className="solution-item-text">
              Extracts Data
              <br />
              from websites
            </p>
          </div>
          <div className="solution-item">
            <img
              src="/images/icon2.png"
              alt="icon2"
              className="solution-item-image"
            />
            <p className="solution-item-text">
              Structures
              <br />
              the data
            </p>
          </div>
          <div className="solution-item">
            <img
              src="/images/icon3.png"
              alt="icon3"
              className="solution-item-image"
            />
            <p className="solution-item-text">
              Leverages algorithms <br />to personalise data
            </p>
          </div>
        </div>
      </div>
    );
  }
}
