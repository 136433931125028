import React, { Component } from "react";
import { Col, Row, Container } from "react-bootstrap";

export default class Footer extends Component {
  render() {
    return (
      <Container>
        <Row className="footer">

          <img
            src="/images/bountye2.png"
            alt="bountye"
            className="footer-column-left-image"
          />
          {/* <p className="footer-column-left-copy-right">
            © 2020 - All Rights Reserved
            </p> */}
          <p className="footer-column-left-copy-right">
            © {new Date().getFullYear()} - All Rights Reserved
            </p>

          {/* <Col xs="12" md="6" className="footer-column-right ">
            <Row xs="12" md="12" className="footer-column-right-item ">
              <Col xs="6" md="4" className="footer-column-right-item-group">
                <label className="footer-column-right-item-title">
                  COMPANY
                </label>
                <a href="/" className="footer-column-right-item-link">
                  About Us
                </a>
                <a href="contact" className="footer-column-right-item-link">
                  Contact Us
                </a>
              </Col>

              <Col xs="6" md="4" className="footer-column-right-item-group">
                <label className="footer-column-right-item-title">LEGAL</label>

                <a href="privacy" className="footer-column-right-item-link">
                  Privacy
                </a>
                <a href="privacy-policy" className="footer-column-right-item-link">
                  Privacy Policy
                </a>
                <a href="terms" className="footer-column-right-item-link">
                  Terms of Use
                </a>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Container>
    );
  }
}
