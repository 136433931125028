import React, { Component } from "react";
import CardBox from "./card";
import { Row, Col } from "react-bootstrap";

class YourStore extends Component {
  render() {
    return (
      <Row className="yourStore">
        <Col className="yourStore-box">
          <div className="yourStore-box-header">
            <div>
              <i className="mdi mdi-menu" />
            </div>
            <div>
              <h4 className="yourStore-box-header-text">YOUR STORE</h4>
            </div>
            <div>
              <i className="mdi mdi-magnify yourStore-box-header-icon" />
              <i className="mdi mdi-cart-outline yourStore-box-header-icon" />
            </div>
          </div>
          <div className="yourStore-box-header-card-group">
            <CardBox
              src={"/images/1.png"}
              title="Maxime Dress"
              description="Puff Sleeve"
              icon={"/images/ebay.png"}
            />
            <CardBox

              src={"/images/2.jpg"}
              title="Polarized Sun.."
              description="Uomo"
              icon={"/images/amazon.png"}
            />
            <CardBox

              src={"/images/3.jpg"}
              title="Golden Brace.."
              description="FORGE"
              icon={"/images/alibaba.png"}
            />
            <CardBox

              src={"/images/4.jpg"}
              title="Wood Planter"
              description="Plant Pot"
              icon={"/images/amazon.png"}
            />
          </div>
          <div className="yourStore-box-button-position">
            <button className="yourStore-box-button"><i className="mdi mdi-check" /> <span>Products imported successfully</span></button>
          </div>
          <div className="yourStore-box-bottom-text-group">
            <p className="yourStore-box-bottom-text-icon">
              <i className="mdi mdi-check" />
              <span className="yourStore-box-bottom-text">
                Extracts from any websites
              </span>
            </p>
            <p className="yourStore-box-bottom-text-icon">
              <i className="mdi mdi-check" />
              <span className="yourStore-box-bottom-text">
                Structures the data
              </span>
            </p>
            <p className="yourStore-box-bottom-text-icon">
              <i className="mdi mdi-check" />
              <span className="yourStore-box-bottom-text">
                Allows the data to be searchable
              </span>
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}
export default YourStore;
