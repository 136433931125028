import React, { Component } from "react";

export default class ContactInfoItem extends Component {
  render() {
    const { src, title, description, icon } = this.props;
    return (
      <div style={{ textAlign: "center" }}>
        <img src={src} style={{ marginTop: 24 }} />
        <p className="contactInfo-title">{title}</p>
        <p className="contactInfo-title text-muted">{description}</p>
      </div>
    );
  }
}
